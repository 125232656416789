
import { Link } from "react-router-dom";
import { Headline } from "../components/Headline";
import { Container } from "../components/Container";
import { PageContent } from "../components/PageContent";
import jaEntendi1 from "../assets/images/projects/jaentendi-1.png";
import jaEntendi2 from "../assets/images/projects/jaentendi-2.png";
import jaEntendi3 from "../assets/images/projects/jaentendi-3.png";
import jaEntendi4 from "../assets/images/projects/jaentendi-4.png";
import jaEntendi5 from "../assets/images/projects/jaentendi-5.png";
import jaEntendi6 from "../assets/images/projects/jaentendi-6.png";
import { ChangePageTitle } from "../components/ChangePageTitle";

export function JaEntendi() {

   return (
      <Container mainClass="project">
         <ChangePageTitle pageTitle="Karine · Já Entendi" />
         <Headline
            projectPage={true}
            title="Já Entendi"
            subtitle="Soluções para educação corporativa · dez 2015"
         />

         <div className="project__image">
            <img src={jaEntendi1} alt="Featured" />
         </div>

         <div className="project__infos">
            <PageContent type="Overview">
               <p>
                  Based on an innovative methodology, Já Entendi offers quality personalized corporate education courses. The company provides any type of training content, such as handouts and manuals, in video classes that facilitate the assimilation of the content.
               </p>
               <p>
                  I worked along with the UX/UI designer who created the modern design og já Entendi's landing page. It was entirely built with cross-browser and responsive compatibility to ensure the website works across a wide range of devices from mobile to desktop.
               </p>
               <div className="project__links">
                  <a className="link__primary" href="https://jaentendi.com.br/" target="_blank" rel="noopener noreferrer">Go to website</a>
               </div>
            </PageContent>

            <div className="offset"></div>

            <PageContent type="Details">
               <ul>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>Responsive Design</li>
               </ul>
            </PageContent>
         </div>

         <div className="project__details">

            <div className="project__grid">
               <div className="project-item">
                  <img src={jaEntendi2} alt="Featured" />
               </div>

               <div className="project-item">
                  <img src={jaEntendi3} alt="Featured" />
               </div>
            </div>

            <div className="project__full">
               <div className="project-item">
                  <img src={jaEntendi4} alt="Artista Con HomePage" />
               </div>
            </div>

            <div className="project__grid">
               <div className="project-item">
                  <img src={jaEntendi5} alt="Featured" />
               </div>

               <div className="project-item pull-xl">
                  <img src={jaEntendi6} alt="Featured" />
               </div>
            </div>

         </div>
         <div className="project__footer">
            <Link className="link link__default" to="/projects">
               Back to projects
            </Link>
         </div>

      </Container>
   );
}