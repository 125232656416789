import "../assets/scss/pages/home.scss";
import { Link } from "react-router-dom";
import { Container } from "../components/Container";
import resume from "../assets/files/Karine_CV_ Frontend.pdf";

export function Home() {
   return (
      <Container mainClass="home">

         <h2 className="home__statement">
            Hello! I’m karine, a Brazilian Front-End Developer currently living in Lisbon, Portugal. I have a huge passion for development of intuitive user experiences and UI design.
            Driven by collaboration and commitment, I'm an organized person with high attention to detail, problem solver and independent employee. I also enjoy graphic design and illustration.
         </h2>
         <div className="home__links">
            <p>See <Link className="link__primary" to="/projects">my projects</Link></p>
            <p>Get to know <Link className="link__primary" to="/about">about me</Link></p>
            <p>My <a className="link__primary" href={resume} target="_blank" rel="noreferrer">resume</a></p>
         </div>

      </Container>

   );
}