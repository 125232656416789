
import { Headline } from "../components/Headline";
import { Container } from "../components/Container";
import { PageContent } from "../components/PageContent";
import twigVsForest1 from "../assets/images/projects/twigvsforest-1.png";
import twigVsForest2 from "../assets/images/projects/twigvsforest-2.png";
import twigVsForest3 from "../assets/images/projects/twigvsforest-3.png";
import twigVsForest4 from "../assets/images/projects/twigvsforest-4.gif";
import twigVsForest5 from "../assets/images/projects/twigvsforest-5.png";
import { Link } from "react-router-dom";
import { ChangePageTitle } from "../components/ChangePageTitle";

export function TwigVsForest() {

   return (
      <Container mainClass="project">
         <ChangePageTitle pageTitle="Karine · Twig vs Forest" />
         <Headline
            projectPage={true}
            title="Twig vs Forest"
            subtitle="Help Twig cross the dark forest · nov 2022"
         />

         <div className="project__image">
            <img src={twigVsForest1} alt="Featured" />
         </div>

         <div className="project__infos">
            <PageContent type="Overview">
               <p>
                  It's a platform-style game called Twig vs Forest.
               </p>

               <p>
                  The player must control the main character, Twig, to cross the forest and defeat the enemies that come along the way. The player earn points for each enemy destroyed and lose a life when Twig gets hit.
               </p>

               <p>
                  I've chosen this kind of game because it was part of my childhood. Also, I followed a very detailed tutorial from the Franks Laboratory channel.
               </p>

               <div className="project__links">
                  <a className="link__primary" href="https://kahzitacodes.github.io/game-project/" target="_blank" rel="noopener noreferrer">Go to game</a>
                  <a className="link__primary" href="https://github.com/kahzitacodes/game-project" target="_blank" rel="noopener noreferrer">GitHub</a>
               </div>
            </PageContent>

            <div className="offset"></div>

            <PageContent type="Details">
               <ul>
                  <li>JavaScript</li>
                  <li>HTML</li>
                  <li>CSS</li>
               </ul>
            </PageContent>
         </div>

         <div className="project__details">

            <div className="project__full">
               <div className="project-item">
                  <img src={twigVsForest2} alt="Artista Con HomePage" />
               </div>
            </div>

            <div className="project__full">
               <PageContent type="More about the game">
                  <p>
                     The fox deer is based on a character called Twig from the animation Hilda. Furthermore, the entire scenario was designed based on the art of the illustrator Ellie vs Bear.
                  </p>

               </PageContent>
            </div>

            <div className="project__grid">
               <div className="project-item">
                  <img src={twigVsForest3} alt="Featured" />
               </div>

               <div className="project-item">
                  <img src={twigVsForest4} alt="Featured" />
               </div>
            </div>

            <div className="project__full">
               <div className="project-item">
                  <img src={twigVsForest5} alt="Artista Con HomePage" />
               </div>
            </div>


         </div>
         <div className="project__footer">
            <Link className="link link__default" to="/projects">
               Back to projects
            </Link>
         </div>
      </Container>
   );
}