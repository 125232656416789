import "../assets/scss/components/page-content.scss";

export function PageContent(props) {

   const { type, title } = props;

   return (
      <div className={`page-content ${title ? title.toLowerCase() : type.toLowerCase()}`}>

         <h3 className="page-content__title">{title ? title : type}</h3>

         {props.children}

      </div>
   );
}