import "../assets/scss/components/headline.scss";

export function Headline({ title, subtitle, projectPage }) {

   return (
      <div className="headline">

         <div className="headline__content">
            <h2 className="headline__title">{title}</h2>
            {subtitle ? <p className="headline__subtitle">{subtitle}</p> : null}
         </div>
      </div>
   );
}