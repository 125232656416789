export function Container(props) {

   const { mainClass } = props;

   return (
      <div className={`main ${mainClass}`}>
         <div className="container container__grid">
            <div className="container__offsetLeft"></div>
            <div className={`container__content ${mainClass}__content`}>

               {props.children}

            </div>
            <div className="container__offsetRight"></div>
         </div>
      </div>
   );
}