
import { Headline } from "../components/Headline";
import { Container } from "../components/Container";
import { PageContent } from "../components/PageContent";
import cinelist1 from "../assets/images/projects/cinelist-1.png";
import cinelist2 from "../assets/images/projects/cinelist-2.png";
import cinelist3 from "../assets/images/projects/cinelist-3.png";
import cinelist4 from "../assets/images/projects/cinelist-video.mp4";
import cinelist5 from "../assets/images/projects/cinelist-5.png";
import { Link } from "react-router-dom";
import { ChangePageTitle } from "../components/ChangePageTitle";

export function Cinelist() {

   return (
      <Container mainClass="project">
         <ChangePageTitle pageTitle="Karine · Cinelist" />
         <Headline
            projectPage={true}
            title="CineList"
            subtitle="Share recomendations of your favorite movies · Dez 2022"
         />

         <div className="project__image">
            <img src={cinelist1} alt="Featured" />
         </div>

         <div className="project__infos">
            <PageContent type="Overview">
               <p>
                  CineList is a React app where people can create lists with their favorite movies. The lists are placed on Cinelist's homepage, so other users can see it.
               </p>

               <p>
                  The user can create a name and a description for their lists, as well as searching and adding movies to the list from <i>The Movie Database API</i>. It't also posible to edit and delete a list.
               </p>

               <p>
                  In addition, the app is responsive and adjusts itself for different screens.
               </p>
            </PageContent>

            <div className="offset"></div>

            <PageContent type="Details">
               <ul>
                  <li>ReactJS</li>
                  <li>Axios</li>
                  <li>HTML</li>
                  <li>CSS</li>
               </ul>
            </PageContent>
            <div className="project__links">
               <a className="link__primary" href="https://thecinelist.netlify.app/" target="_blank" rel="noopener noreferrer">Go to website</a>
               <a className="link__primary" href="https://github.com/kahzitacodes/cinelist" target="_blank" rel="noopener noreferrer">GitHub</a>
            </div>


         </div>

         <div className="project__details">

            <div className="project__grid">
               <div className="project-item">
                  <img src={cinelist2} alt="Artista Con HomePage" />
               </div>

               <div className="project-item">
                  <img src={cinelist3} alt="Featured" />
               </div>
            </div>

            <div className="project__full">
               <div className="project-item video" style={{ backgroundColor: "#000" }}>
                  <video preload="auto" loop="true" muted="true" autoplay="true" playsinline="">
                     <source src={cinelist4} type="video/mp4" />
                  </video>
               </div>
            </div>

            <div className="project__full">
               <div className="project-item">
                  <img src={cinelist5} alt="Featured" />
               </div>
            </div>

         </div>

         <div className="project__footer">
            <Link className="link link__default" to="/projects">
               Back to projects
            </Link>
         </div>

      </Container>
   );
}