import './assets/scss/components/app.scss';
import { Route, Routes, useLocation } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

import { Home } from "./pages/Home";
import { AboutMe } from "./pages/AboutMe";
import { Projects } from "./pages/Projects";
import { Cinelist } from "./pages/Cinelist";
import { TwigVsForest } from "./pages/TwigVsForest";
import { ArtistaCon } from "./pages/ArtistaCon";
import { JaEntendi } from "./pages/JaEntendi";

function App() {

  const { pathname } = useLocation();
  const darkPlaces = ["/projects", "/projects/*"];

  let theme = "default";
  let page = "";

  if (pathname === "/") {
    page = 'home';
  }

  if (darkPlaces.some((item) => pathname.includes(item))) {
    theme = "inverse";
  };


  return (
    <div id="portfolio" className={`${theme} ${page}`}>

      <Header theme={theme} />
      <Routes>

        <Route path="/" element={<Home />} />

        <Route path="about" element={<AboutMe />} />

        <Route path="projects" element={<Projects />} />

        <Route path="projects/artistacon" element={<ArtistaCon />} />
        <Route path="projects/cinelist" element={<Cinelist />} />
        <Route path="projects/twigvsforest" element={<TwigVsForest />} />
        <Route path="projects/jaentendi" element={<JaEntendi />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
