import '../assets/scss/components/footer.scss';

export function Footer() {
   return (
      <footer className="footer text-dark">
         <div className="container footer__container">

            <div className="footer__links">
               <span>karine@yakistudio.com</span>  <span>/</span>  <span>+351 91258 3405</span>
            </div>

            <div className="footer__links">
               <a className="link__icon" href="https://www.linkedin.com/in/karine-pereira/" target="_blank" rel="noopener noreferrer">
                  <i className="i__linkedin"></i>
                  Linkedin
               </a>
               <a className="link__icon" href="https://github.com/kahzitacodes" target="_blank" rel="noopener noreferrer">
                  <i className="i__github"></i>
                  Github
               </a>
            </div>

         </div>
      </footer>
   );
}