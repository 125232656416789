
import { Headline } from "../components/Headline";
import { Container } from "../components/Container";
import { PageContent } from "../components/PageContent";
import { Link } from "react-router-dom";
import artistaCon1 from "../assets/images/projects/artistacon-1.png";
import artistaCon2 from "../assets/images/projects/artistacon-2.png";
import artistaCon3 from "../assets/images/projects/artistacon-3.png";
import artistaCon4 from "../assets/images/projects/artistacon-4.png";
import artistaCon5 from "../assets/images/projects/artistacon-5.png";
import artistaCon6 from "../assets/images/projects/artistacon-mc1.png";
import artistaCon7 from "../assets/images/projects/artistacon-mc2.png";
import artistaCon8 from "../assets/images/projects/artistacon-mc3.png";
import { ChangePageTitle } from "../components/ChangePageTitle";

export function ArtistaCon() {

   return (
      <Container mainClass="project">
         <ChangePageTitle pageTitle="Karine · Artista Con" />
         <Headline
            projectPage={true}
            title="Artista Con"
            subtitle="Independent Comic Convention online  · Jan 2023"
         />

         <div className="project__image">
            <img src={artistaCon1} alt="Featured" />
         </div>

         <div className="project__infos">
            <PageContent type="Overview">
               <p>
                  Artista Con is a comic convention organized for independent artists to exhibit their work, similar to what happens in usual comic book events, anime, games and crafts, however it is 100% online.
               </p>
               <p>
                  The original event was idealized by close friends of mine and happened during the pandemic of COVID-19. My intention was to recreate the online platform improving features and usability.
               </p>

               <p>
                  These conventions are essential for small and independent artists, hence the idea of exposing web stores and projects by artists, comic artists, authors, artisans and anyone who wants to exhibit, sell or buy original products.
               </p>

               <div className="project__links">
                  <a className="link__primary" href="https://artistacon.netlify.app/" target="_blank" rel="noopener noreferrer">Go to website</a>
                  <a className="link__primary" href="https://github.com/kahzitacodes/artistacon-api" target="_blank" rel="noopener noreferrer">Server GitHub</a>
                  <a className="link__primary" href="https://github.com/kahzitacodes/artistacon-client" target="_blank" rel="noopener noreferrer">Client GitHub</a>
               </div>
            </PageContent>

            <div className="offset"></div>
            <PageContent type="Details">
               <ul>
                  <li>ReactJS</li>
                  <li>Node.js</li>
                  <li>Bootstrap</li>
                  <li>MongoDB</li>
                  <li>Express</li>
                  <li>Mongoose</li>
                  <li>Bcrypt</li>
                  <li>Dotenv</li>
                  <li>Axios</li>
               </ul>
            </PageContent>
         </div>

         <div className="project__details">
            <div className="project__full">
               <div className="project-item">
                  <img src={artistaCon2} alt="Artista Con HomePage" />
               </div>
            </div>

            <div className="project__grid">
               <div className="project-item">
                  <img src={artistaCon3} alt="Artista Con HomePage" />
               </div>

               <div className="project-item">
                  <img src={artistaCon4} alt="Featured" />
               </div>
            </div>

            <div className="project__full">
               <PageContent type="Artist's page">
                  <p>
                     Each exhibitor has their own page, with a brief description and presentation of their work and a link to their store. The general public will be able to know different artists and their products in an easy and organized way. The visitor can also save their favorite products to a list for a later purchasing.
                  </p>

               </PageContent>
            </div>

            <div className="project__full">
               <div className="project-item">
                  <img src={artistaCon5} alt="Artista Con HomePage" />
               </div>
            </div>

            <div className="project__grid">
               <div className="project-item">
                  <img src={artistaCon7} alt="Artista Con HomePage" />
               </div>

               <div className="project-item">
                  <img src={artistaCon8} alt="Featured" />
               </div>
            </div>

            <div className="project__full">
               <div className="project-item">
                  <img src={artistaCon6} alt="Artista Con HomePage" />
               </div>
            </div>

         </div>

         <div className="project__footer">
            <Link className="link link__default" to="/projects">
               Back to projects
            </Link>
         </div>
      </Container>
   );
}