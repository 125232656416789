import "../assets/scss/pages/projects.scss";
import { Headline } from "../components/Headline";
import { Container } from "../components/Container";
import { ProjectCard } from "../components/ProjectCard";
import artistaCon from "../assets/images/projects/artistacon-0.png";
import twigVsForest from "../assets/images/projects/twigvsforest-0.png";
import cinelist from "../assets/images/projects/cinelist-0.png";
import jaEntendi from "../assets/images/projects/jaentendi-0.png";
import { ChangePageTitle } from "../components/ChangePageTitle";

export function Projects() {
   return (
      <Container mainClass="projects">
         <ChangePageTitle pageTitle="Karine · Projects" />
         <Headline
            title="Projects"
            subtitle="Bellow you'll find a selection of my previous work"
         />
         <div className="projects__grid">

            <ProjectCard
               path="/projects/artistacon"
               title="Artista Con"
               featuredImage={artistaCon}
               type="Website"
            />

            <ProjectCard
               path="/projects/cinelist"
               title="CineList"
               featuredImage={cinelist}
               type="Website"
            />

            <ProjectCard
               path="/projects/twigvsforest"
               title="Twig vs Forest"
               featuredImage={twigVsForest}
               type="Game"
            />

            <ProjectCard
               path="/projects/jaentendi"
               title="Já Entendi"
               featuredImage={jaEntendi}
               type="Website"
            />
         </div>
      </Container>
   );
}