import "../assets/scss/components/project-card.scss";
import { Link } from "react-router-dom";

export function ProjectCard({ featuredImage, title, type, path }) {
   return (
      <Link to={path} className="project-card">

         <div className="project-card__image">
            <img src={featuredImage} alt={title} />
         </div>

         <div className="project-card__infos">
            <h3>{title}</h3>
            <p>{type}</p>
         </div>

      </Link>
   );
}