import { Container } from "../components/Container";
import { Headline } from "../components/Headline";
import { PageContent } from "../components/PageContent";
import resume from "../assets/files/Karine_CV_ Frontend.pdf";
import me from "../assets/images/me.png";
import { ChangePageTitle } from "../components/ChangePageTitle";

export function AboutMe() {
   return (

      <Container mainClass="about">
         <ChangePageTitle pageTitle="Karine · About" />
         <Headline
            title="About me"
            subtitle="A creative web developer"
         />

         <div className="about__image">
            <img src={me} alt="Myself" />
         </div>

         <div className="about__infos">
            <PageContent type="overview" title="Who am I?">
               <p className="larger">
                  I am a front-end developer with 10 years of experience in user experience and interface design. I have a strong passion for delivering exceptional interfaces and user experiences.
               </p>
               <p className="larger">
                  I've always been interested in art, design and technology, and that is the reason why I graduated in Digital Design. In the beginning of my career as a UI/UX designer I also learned and worked with HTML5 & CSS3, SASS, Bootstrap, Git and Github.
               </p>
               <p>
                  At some point in my career, I started to feel that I needed to do something different, more technical and aligned with my professional profile. As an outcome, I decided to become a web developer and took a fulltime web development bootcamp at IronHack.
               </p>
               <p className="larger">
                  As a front-end developer I’d love to be able to improve my knowledge about the languages and frameworks I've learned so far. Also, I'd like to deepen the back-end to become a more sufficient professional. In addition, I intend to enhance my abilities to build clean, readable and accessible code.
               </p>

               <div className="about__links">
                  <p className="larger">My <a className="link__primary" href={resume} target="_blank" rel="noreferrer">resume</a></p>
               </div>
            </PageContent>

            <div className="offset"></div>

            <PageContent type="details" title="Skills">
               <ul className="no-bullet">
                  <li>JavaScript</li>
                  <li>Next</li>
                  <li>React</li>
                  <li>HTML5 & CSS3</li>
                  <li>SCSS / SASS</li>
                  <li>REST API</li>
                  <li>Git & GitHub</li>
                  <li>Styled Components</li>
                  <li>Node</li>
                  <li>Nest</li>
                  <li>Express</li>
                  <li>MongoDB & Mongoose</li>
                  <li>Axios</li>
                  <li>Responsive Design</li>
                  <li>Bootstrap</li>
                  <li>UX / UI Design</li>
                  <li>Figma e Sketch</li>
                  <li>Agile methodologies</li>
               </ul>
            </PageContent>
         </div>

      </Container>
   );
}