import '../assets/scss/components/header.scss';
import logoDark from '../assets/images/logo-full-dark.svg';
import logoLight from '../assets/images/logo-full-light.svg';
import { Link } from 'react-router-dom';
import { Menu } from "./Menu";
import { useEffect, useState } from "react";
import { useViewport } from '../hooks/useViewport';

export function Header({ theme }) {

   const [showMenu, setShowMenu] = useState(false);
   const [currentLogo, setCurrentLogo] = useState(logoLight)

   const { width } = useViewport();
   const isMobileSize = width < 768;

   function handleMenu() {
      if (showMenu) {
         setShowMenu(false);
      } else {
         setShowMenu(true);
      }
   };

   useEffect(() => {
      if (theme === 'inverse' || (isMobileSize && showMenu)){
         setCurrentLogo(logoLight)
      } else {
         setCurrentLogo(logoDark)
      }
   }, [theme, isMobileSize, showMenu])

   return (
      <header className={`header ${showMenu ? 'show__menu' : ''}`}>
         <div className="container header__container">

            <Link className="header__logo" to="/" onClick={showMenu ? handleMenu : null}>
               <img src={currentLogo} alt="Logo" />
            </Link>

            <button onClick={handleMenu} className="header__menu">
               <span></span>
               <span></span>
            </button>

            <Menu handleMenu={handleMenu} />
         </div>
      </header>
   );
}